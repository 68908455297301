export default [
  {
    title: 'P&L',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'P&L Overview',
        route: 'p&l-overview',
      },
      {
        title: 'P&L Breakdown',
        route: 'p&l-breakdown',
      },
      {
        title: 'Other Transaction',
        route: 'p&l-transaction',
      },
    ],
  },
  {
    title: 'Orders / Sales',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Orders by ASIN',
        route: 'orders-by-asin',
      },
      {
        title: 'Orders by Recency',
        route: 'orders-by-recency',
      },
      {
        title: 'Velocity / Trends',
        route: 'sales-velocity-trends',
      },
      {
        title: 'Forecasts',
        route: 'sales-forecasts',
      },
    ],
  },
  {
    title: 'Insights',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Customer Insight',
      },
      {
        title: 'Product Reviews',
      },
      {
        title: 'Seller Reviews',
      },
      {
        title: 'Account Health',
      },
      {
        title: 'FBA Fees Changes',
      },
      {
        title: 'Inventory Issues',
      },
      {
        title: 'ASIN Traffic',
      },
      {
        title: 'Watch List',
      },
    ],
  },
  {
    title: 'Inventory / Product',
    icon: 'PackageIcon',
  },
  {
    title: 'Emails to Buyers',
    icon: 'MailIcon',
  },
  {
    title: 'Homepage',
    icon: 'TrelloIcon',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Notifications',
      },
      {
        title: 'Subscription Info',
      },
      {
        title: 'Invoices',
      },
    ],
  },
]
